import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import * as GlobalStyles from 'src/styles/global.module.scss'
import { PrismicLinkType } from 'src/typings/generated/graphql'
import { Link } from 'gatsby'
import * as Styles from './crewOverlay.module.scss'

export type CrewRisingItem = {
  image?: IGatsbyImageData
  imageAlt?: string
  position?: string
}

export type CrewOverlayContent = {
  active?: boolean
  eyebrow?: string
  headline?: string
  imageSrc?: IGatsbyImageData
  imageAlt?: string
  description?: string
  linkText?: string
  link?: PrismicLinkType
  onCloseOverlay?: (event: React.MouseEvent<HTMLButtonElement>) => void
  showPrevious?: boolean
  showNext?: boolean
  onNextClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onBackClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

type Props = {
  content: CrewOverlayContent
}

const CrewOverlay = ({ content }: Props) => {
  const {
    active = false,
    eyebrow = '',
    headline = '',
    description = '',
    linkText = '',
    link,
    imageAlt = '',
    imageSrc = undefined,
    onCloseOverlay,
    onNextClick,
    onBackClick,
    showNext = true,
    showPrevious = true,
  } = content

  return (
    <div
      className={`${Styles.crewOverlayBackground} ${
        active && Styles.activeOverlay
      }`}
    >
      <div className={Styles.crewOverlayContainer}>
        <div className={Styles.crewOverlayContentInner}>
          {imageSrc && (
            <GatsbyImage
              className={Styles.overlayImage}
              image={imageSrc}
              alt={imageAlt}
            />
          )}
          <div className={Styles.overlayContent}>
            <button
              type="button"
              className={Styles.closeOverlayButton}
              aria-label="Close overlay"
              onClick={onCloseOverlay}
            />
            <p className={Styles.overlayEyebrow}>{eyebrow}</p>
            <h2>{headline}</h2>
            <p>{description}</p>
          </div>
        </div>
        <div className={Styles.buttonContainer}>
          <div>
            {showPrevious && (
              <button
                onClick={onBackClick}
                type="button"
                className={`${Styles.prevLink}`}
              >
                <span className={Styles.backArrow} />
                Back
              </button>
            )}
          </div>
          <Link
            className={`${GlobalStyles.redButton}`}
            to={link?.url || '#'}
            target={link?.target || undefined}
          >
            {linkText}
          </Link>
          <div>
            {showNext && (
              <button
                onClick={onNextClick}
                type="button"
                className={`${Styles.nextLink}`}
              >
                Next
                <span className={Styles.nextArrow} />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CrewOverlay
