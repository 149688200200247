// extracted by mini-css-extract-plugin
export var activeOverlay = "crewOverlay-module--activeOverlay--cfae5";
export var backArrow = "crewOverlay-module--backArrow--6caf4";
export var buttonContainer = "crewOverlay-module--buttonContainer--f239c";
export var closeOverlayButton = "crewOverlay-module--closeOverlayButton--7f323";
export var crewOverlayBackground = "crewOverlay-module--crewOverlayBackground--ce7e0";
export var crewOverlayContainer = "crewOverlay-module--crewOverlayContainer--d9149";
export var crewOverlayContentInner = "crewOverlay-module--crewOverlayContentInner--18b9f";
export var nextArrow = "crewOverlay-module--nextArrow--7c151";
export var nextLink = "crewOverlay-module--nextLink--9275e";
export var overlayContent = "crewOverlay-module--overlayContent--7526b";
export var overlayEyebrow = "crewOverlay-module--overlayEyebrow--17b6a";
export var overlayImage = "crewOverlay-module--overlayImage--10917";
export var prevLink = "crewOverlay-module--prevLink--4ef79";