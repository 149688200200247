import React, { useState } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import {
  PrismicLinkType,
  PrismicStructuredTextType,
} from 'src/typings/generated/graphql'
import BertholdTitle from 'src/components/atoms/bertholdTitle'

import Eyebrow from 'src/images/svgs/Eyebrow.svg'
import StepArrow from 'src/images/svgs/StepArrow.svg'

import * as GlobalStyles from 'src/styles/global.module.scss'

import CustomLink from 'src/utils/customLink'
import DescriptiveCard from 'src/components/molecules/cards/descriptiveCard'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './crewRising.module.scss'
import CrewOverlay from './crewOverlay'

export type CrewRisingItem = {
  image?: IGatsbyImageData
  imageAlt?: string
  position?: string
  eyebrowText?: string
  linkText?: string
  link?: PrismicLinkType
  description?: string
}

export type CrewRisingContent = {
  anchor?: string
  description?: PrismicStructuredTextType
  eyebrowText?: string
  headline?: string
  items?: CrewRisingItem[]
  link?: PrismicLinkType
  linkText?: string
}

type Props = {
  content: CrewRisingContent
}

const CrewRising = ({ content }: Props) => {
  const [activeCrewOverlay, setActiveCrewOverlay] = useState<number | null>(
    null
  )
  const {
    anchor = generateRandomId(),
    description,
    eyebrowText = '',
    headline = '',
    items = [],
    link,
    linkText = '',
  } = content
  const eyebrow = () => {
    if (eyebrowText) return <p>{eyebrowText}</p>

    return (
      <div className={Styles.eyebrow}>
        <Eyebrow />
      </div>
    )
  }

  const handleActiveOverlay = (index: number) => {
    setActiveCrewOverlay(index + 1)
  }

  return (
    <section id={anchor} className={Styles.crewRising}>
      <div className={Styles.content}>
        {eyebrow()}
        <div className={Styles.copy}>
          <h2>{BertholdTitle({ text: headline })}</h2>
          {description && (
            <PrismicRichText
              field={description?.richText}
              components={{
                hyperlink: ({ node, children }: any) =>
                  CustomLink({ link: node.data, children }),
              }}
            />
          )}
        </div>
        <CustomLink className={GlobalStyles.redBasicLink} link={link}>
          {linkText}
        </CustomLink>
      </div>
      {items.map((item, index: number) => (
        <CrewOverlay
          content={{
            active: activeCrewOverlay === index + 1,
            description: item.description,
            eyebrow: item.eyebrowText,
            headline: item.position,
            imageAlt: item.imageAlt,
            imageSrc: item.image,
            link: item.link,
            linkText: item.linkText,
            onBackClick: () =>
              activeCrewOverlay && setActiveCrewOverlay(activeCrewOverlay - 1),
            onCloseOverlay: () => setActiveCrewOverlay(null),
            onNextClick: () =>
              activeCrewOverlay && setActiveCrewOverlay(activeCrewOverlay + 1),
            showNext: index + 1 < items.length,
            showPrevious: index !== 0,
          }}
        />
      ))}
      <div className={Styles.positions}>
        {items.map((item, index: number) => {
          const key = `crewRisingPositions-${index}`
          return (
            <div className={Styles.positionWrapper} key={key}>
              <div className={Styles.mobile}>
                {!!item.image && (
                  <GatsbyImage
                    className={Styles.image}
                    image={item.image}
                    alt={item.imageAlt ?? ''}
                  />
                )}
              </div>
              <div className={Styles.web}>
                <div className={Styles.position}>
                  <div className={Styles.cardNumber}>{index + 1}</div>
                  <DescriptiveCard
                    title={item.position ?? ''}
                    image={item.image}
                    alt={item.imageAlt ?? ''}
                    scaleHeadlineFont
                    hasOverlay
                    onButtonClick={() => handleActiveOverlay(index)}
                  />
                </div>
                <div className={Styles.stepArrow}>
                  <StepArrow />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default CrewRising
