import { graphql } from 'gatsby'
import React from 'react'
import CrewRising, {
  CrewRisingContent,
  CrewRisingItem,
} from 'src/components/organisms/crewConversion/crewRising'
import { PrismicPageDataBodyCrewRising } from 'src/typings/generated/graphql'

type Props = {
  slice: PrismicPageDataBodyCrewRising
}

const CrewRisingSlice = ({ slice }: Props) => {
  const content: CrewRisingContent = {
    anchor: slice.primary.anchor ?? undefined,
    description: slice.primary.description ?? undefined,
    eyebrowText: slice.primary.eyebrow_text ?? undefined,
    headline: slice.primary.headline ?? undefined,
    items: slice.items.map(
      item =>
        ({
          image: item.image?.gatsbyImageData ?? undefined,
          imageAlt: item.image?.alt ?? undefined,
          position: item?.position ?? undefined,
          eyebrowText: item?.eyebrow_text ?? undefined,
          description: item?.description?.text ?? undefined,
          linkText: item?.link_text ?? undefined,
          link: item?.link ?? undefined,
        } as CrewRisingItem)
    ),
    link: slice.primary.link ?? undefined,
    linkText: slice.primary.link_text ?? undefined,
  }
  return <CrewRising content={content} />
}

export default CrewRisingSlice

export const CrewRisingFragment = graphql`
  fragment PageDataBodyCrewRising on PrismicPageDataBodyCrewRising {
    primary {
      anchor
      eyebrow_text
      headline
      description {
        richText
      }
      link_text
      link {
        ...BasicLinkFragment
      }
    }
    items {
      eyebrow_text
      link_text
      position
      image {
        gatsbyImageData
        alt
      }
      description {
        text
      }
      link {
        ...BasicLinkFragment
      }
    }
  }
`
